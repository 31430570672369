









































































































































































































































// @ is an alias to /src
import { Vue, Component } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "./store";
import PgaVerbaleStartupHeaderCreate from "./Components/PgaVerbaleStartupHeaderCreate.vue";
import PgaVerbaleStartupSubHeaderCreate from "./Components/PgaVerbaleStartupSubHeaderCreate.vue";
import PgaVerbaleStartupDeliverableCreate from "./Components/PgaVerbaleStartupDeliverablesCreate.vue";
import PgaVerbaleStartupReferenteClienteCreate from "./Components/PgaVerbaleStartupReferenteClienteCreate.vue";
import PgaVerbaleStartupAdempimentiTeamHeaderCreate from "./Components/PgaVerbaleStartupAdempimentiTeamHeaderCreate.vue";
import PgaVerbaleStartupAdempimentiTeamConsultantCreate from "./Components/PgaVerbaleStartupAdempimentiTeamConsultantCreate.vue";
import PgaVerbaleStartupModalitaReportingCreate from "./Components/PgaVerbaleStartupModalitaReportingCreate.vue";
import PgaVerbaleStartupElementiIngressoCreate from "./Components/PgaVerbaleStartupElementiIngressoCreate.vue";
import PgaVerbaleStartupGovernanceProgettoApplicativoCreate from "./Components/PgaVerbaleStartupGovernanceProgettoApplicativoCreate.vue";
import PgaVerbaleStartupAltreInfoProgettoApplicativoCreate from "./Components/PgaVerbaleStartupAltreInfoProgettoApplicativoCreate.vue";
import PgaVerbaleStartupGovernanceServizioManutenzioneCreate from "./Components/PgaVerbaleStartupGovernanceServizioManutenzioneCreate.vue";
import PgaVerbaleStartupAltreInfoServizioManutenzioneCreate from "./Components/PgaVerbaleStartupAltreInfoServizioManutenzioneCreate.vue";
import PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreate from "./Components/PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreate.vue";
import PgaVerbaleStartupAltreInfoProgettoInfrastrutturaCreate from "./Components/PgaVerbaleStartupAltreInfoProgettoInfrastrutturaCreate.vue";

@Component({
  components: {
    PgaVerbaleStartupHeaderCreate,
    PgaVerbaleStartupSubHeaderCreate,
    PgaVerbaleStartupDeliverableCreate,
    PgaVerbaleStartupReferenteClienteCreate,
    PgaVerbaleStartupAdempimentiTeamHeaderCreate,
    PgaVerbaleStartupAdempimentiTeamConsultantCreate,
    PgaVerbaleStartupModalitaReportingCreate,
    PgaVerbaleStartupElementiIngressoCreate,
    PgaVerbaleStartupGovernanceProgettoApplicativoCreate,
    PgaVerbaleStartupAltreInfoProgettoApplicativoCreate,
    PgaVerbaleStartupGovernanceServizioManutenzioneCreate,
    PgaVerbaleStartupAltreInfoServizioManutenzioneCreate,
    PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreate,
    PgaVerbaleStartupAltreInfoProgettoInfrastrutturaCreate
  },
  data: () => ({}),
  computed: {
    PgaVerbaleStartupAltreInfoServizioManutenzioneCreate: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupAltreInfoServizioManutenzioneCreate;
      }
    },
    PgaVerbaleStartupHeaderCreate: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupHeaderCreate;
      }
    },
    PgaVerbaleStartupSubHeaderCreate: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupSubHeaderCreate;
      }
    },
    PgaVerbaleStartupDeliverableCreate: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupDeliverableCreate;
      }
    },
    PgaVerbaleStartupReferenteClienteCreate: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupReferenteClienteCreate;
      }
    },
    PgaVerbaleStartupAdempimentiTeamHeaderCreate: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupAdempimentiTeamHeaderCreate;
      }
    },
    TipologiaVerbaleStepper: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupHeaderCreate.TipologiaVerbale;
      }
    },
    LabelAdempimentiTeam: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupAdempimentiTeamHeaderCreate
          .TipologiaVerbale;
      }
    },
    PgaVerbaleStartupAdempimentiTeamConsultantCreate: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupAdempimentiTeamConsultantCreate;
      }
    },
    PgaVerbaleStartupModalitaReportingCreate: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupModalitaReportingCreate;
      }
    },
    PgaVerbaleStartupElementiIngressoCreate: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupElementiIngressoCreate;
      }
    },
    PgaVerbaleStartupGovernanceProgettoApplicativoCreate: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupGovernanceProgettoApplicativoCreate;
      }
    },
    PgaVerbaleStartupAltreInfoProgettoApplicativoCreate: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupAltreInfoProgettoApplicativoCreate;
      }
    },
    PgaVerbaleStartupGovernanceServizioManutenzioneCreate: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupGovernanceServizioManutenzioneCreate;
      }
    },
    PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreate: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreate;
      }
    },
    PgaVerbaleStartupAltreInfoProgettoInfrastrutturaCreate: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupAltreInfoProgettoInfrastrutturaCreate;
      }
    }
  },
  methods: {
    addDeliverable() {
      CreateVerbaleStartupModule.ADD_DELIVERABLE();
    },
    addReferenteCliente() {
      CreateVerbaleStartupModule.ADD_REFERENTECLIENTE();
    },
    addAdempimentiTeamConsultant() {
      CreateVerbaleStartupModule.ADD_ADEMPIMENTICONSULTANT();
    },
    addElementiIngresso() {
      CreateVerbaleStartupModule.ADD_ELEMENTIINGRESSO();
    }
  }
})
export default class VerbaleStartupPageCreate extends Vue {}
