

































import Vue from "vue";
import { Component, Prop, Model, Emit } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";
import { PgaVerbaleStartupReferenteClienteCreateModel } from "../store";

@Component({
  data: () => ({
    ReferenteCliente: undefined
  }),
  computed: {
    NomeReferenteCliente: {
      get() {
        return this.$props.Model.NomeReferenteCliente;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_REFERENTECLIENTE({
          index: this.$props.index,
          value: value
        });
      }
    },
  }
})
export default class PgaVerbaleStartupReferenteClienteCreate extends Vue {
  @Prop(Number) readonly index!: number;
  @Model("", { type: Object }) readonly Model!: PgaVerbaleStartupReferenteClienteCreateModel;

  @Emit()
  removeReferenteCliente() {
    CreateVerbaleStartupModule.REMOVE_REFERENTECLIENTE(this.index);
  }
}
