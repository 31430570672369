


















































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";
import { PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreateModel } from "../store";

@Component({
  data: () => ({
    panel: [0, 1]
  }),
  computed: {
    PMGantt: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupCampiComuni.PMGanttTeam;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_PMGANTTTEAM(value);
      }
    },
    TabellaMapping: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupCampiComuni.TabellaMapping;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_TABELLAMAPPING(value);
      }
    },
    Standard: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupCampiComuni.Standard;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_STANDARD(value);
      }
    },
    AltroStandard: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupCampiComuni.AltroStandard;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_ALTROSTANDARD(value);
      }
    },
    AltroProgettoInfrastruttura: {
      get() {
        return this.$props.Model.AltroProgettoInfrastruttura;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_ALTROPROGETTOINFRASTRUTTURA(value);
      }
    },
    TestoAltroProgettoInfrastruttura: {
      get() {
        return this.$props.Model.TestoAltroProgettoInfrastruttura;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_TESTOALTROPROGETTO_INFRASTRUTTURA(value);
      }
    },
    GiornoDocumentoArchitettura: {
      get() {
        return this.$props.Model.GiornoDocumentoArchitettura;
      },
      set(value: Date) {
        CreateVerbaleStartupModule.SET_GIORNODOCUMENTOARCHITETTURA(value);
      }
    },
    DocumentoArchitettura: {
      get() {
        return this.$props.Model.DocumentoArchitettura;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_DOCUMENTOARCHITETTURA(value);
      }
    }
  },
  methods: {
    checkGovernance(value) {
      let isVisible: boolean;
      isVisible = false;
      if (value) {
        if (value.includes("Proge")) isVisible = true;
      }
      return isVisible;
    }
  }
})
export default class PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreate extends Vue {
  @Model("", { type: Object })
  readonly Model!: PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreateModel;
}
