




















































































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";
import { PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreateModel } from "../store";

@Component({
  data: () => ({
    panel: [0, 1]
  }),
  computed: {
    PMGantt: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupCampiComuni.PMGanttTeam;
      }
    },
    TabellaMapping: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupCampiComuni.TabellaMapping;
      }
    },
    Standard: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupCampiComuni.Standard;
      }
    },
    AltroStandard: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupCampiComuni.AltroStandard;
      }
    },
    AltroProgettoInfrastruttura: {
      get() {
        return this.$props.Model.AltroProgettoInfrastruttura;
      }
    },
    TestoAltroProgettoInfrastruttura: {
      get() {
        return this.$props.Model.TestoAltroProgettoInfrastruttura;
      }
    },
    GiornoDocumentoArchitettura: {
      get() {
        return this.$props.Model.GiornoDocumentoArchitettura;
      }
    },
    DocumentoArchitettura: {
      get() {
        return this.$props.Model.DocumentoArchitettura;
      }
    }
  },
  methods: {
    checkGovernance(value) {
      let isVisible: boolean;
      isVisible = false;
      if (value) {
        if (value.includes("Proge")) isVisible = true;
      }
      return isVisible;
    }
  }
})
export default class PgaVerbaleStartupGovernanceProgettoInfrastrutturaDetails extends Vue {
  @Model("", { type: Object })
  readonly Model!: PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreateModel;
}
