
































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";
import { PgaVerbaleStartupAltreInfoServizioManutenzioneCreateModel } from "../store";

@Component({
  data: () => ({
    panel: [0, 1]
  }),
  computed: {
    TipologiaManutenzione: {
      get() {
        return this.$props.Model.TipologiaManutenzione;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_TIPOLOGIAMANUTENZIONE(value);
      }
    },
    PMBackup: {
      get() {
        return this.$props.Model.PMBackup;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_PMBACKUP(value);
      }
    },
    PMCriticita: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupCampiComuni.PMSegnalazioneProgettoApllicativo;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_PMSEGNALAZIONE_PROGETTOAPPLICATIVOTESTO(value);
      }
    },
    NoteServiziManutenzione: {
      get() {
        return this.$props.Model.NoteServiziManutenzione;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_NOTESERVIZIMANUTENZIONE(value);
      }
    }
  }
})
export default class PgaVerbaleStartupAltreInfoServizioManutenzioneCreate extends Vue {
  @Model("", { type: Object })
  readonly Model!: PgaVerbaleStartupAltreInfoServizioManutenzioneCreateModel;
}
