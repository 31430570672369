

















import Vue from "vue";
import { Component } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";

@Component({
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Nome Referente Cliente",
          align: "start",
          sortable: false,
          value: "NomeReferenteCliente"
        },
        { text: "Consegna entro il:", value: "DataConsegna" },
      ]
    };
  },
  computed: {
    ReferenteClienteItems: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupReferenteClienteCreate;
      }
    }
  }
})
export default class PgaVerbaleStartupReferenteClienteDetails extends Vue {}
