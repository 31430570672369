



























































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";
import { PgaVerbaleStartupHeaderCreateModel } from "../store";

@Component({
  data: () => ({}),
  computed: {
    nomeCliente: {
      get() {
        return this.$props.Model.NomeCliente;
      },
    },
    data: {
      get() {
        return this.$props.Model.Data;
      },
    },
    siglaMnemonica: {
      get() {
        return this.$props.Model.SiglaMnemonica;
      },
    },
    descrizione: {
      get() {
        return this.$props.Model.Descrizione;
      },
    },
    nominativoPM: {
      get() {
        return this.$props.Model.NominativoPM;
      },
    },
    tipologiaVerbale: {
      get() {
        return this.$props.Model.TipologiaVerbale;
      },
    },
  }
})
export default class PgaVerbaleStartupHeaderDetails extends Vue {
  @Model("", { type: Object }) readonly Model!: PgaVerbaleStartupHeaderCreateModel;
}
