








































































































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";
import { PgaVerbaleStartupAltreInfoProgettoApplicativoCreateModel } from "../store";

@Component({
  data: () => ({
    panel: [0, 1]
  }),
  computed: {
    PMVerificaProgettoApplicativo: {
      get() {
        return this.$props.Model.PMVerificaProgettoApplicativo;
      },
    },
    AltroPMVerificaProgettoApplicativo: {
      get() {
        return this.$props.Model.AltroPMVerificaProgettoApplicativo;
      },
    },
    PMRedazioneProgettoApllicativo: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupCampiComuni.PMRedazioneProgettoApllicativo;
      },
    },
    PMStrumentoProgettoApplicativo: {
      get() {
        return this.$props.Model.PMStrumentoProgettoApplicativo;
      },
    },
    PMStrumentoProgettoApplicativoTesto: {
      get() {
        return this.$props.Model.PMStrumentoProgettoApplicativoTesto;
      },
    },
    TeamPMStrumentoProgettoApplicativo: {
      get() {
        return this.$props.Model.TeamPMStrumentoProgettoApplicativo;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_TEAM_PMSTRUMENTO_PROGETTOAPPLICATIVO(
          value
        );
      }
    },
    TeamPMStrumentoProgettoApplicativoTesto: {
      get() {
        return this.$props.Model.TeamPMStrumentoProgettoApplicativoTesto;
      },
    },
    PMComunicazioneProgettoApllicativo: {
      get() {
        return this.$props.Model.PMComunicazioneProgettoApllicativo;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_PMCOMUNICAZIONE_PROGETTOAPPLICATIVOTESTO(
          value
        );
      }
    },
    PMSegnalazioneProgettoApllicativo: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupCampiComuni.PMSegnalazioneProgettoApllicativo;
      },
    },
    NoteProgettoApplicativoTesto: {
      get() {
        return this.$props.Model.NoteProgettoApplicativoTesto;
      },
    }
  }
})
export default class PgaVerbaleStartupAltreInfoProgettoApllicativoDetails extends Vue {
  @Model("", { type: Object })
  readonly Model!: PgaVerbaleStartupAltreInfoProgettoApplicativoCreateModel;
}
