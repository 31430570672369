
















































































// @ is an alias to /src
import { Vue, Component } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "./store";
import PgaVerbaleStartupHeaderDetails from "./Components/PgaVerbaleStartupHeaderDetails.vue";
import PgaVerbaleStartupSubHeaderDetails from "./Components/PgaVerbaleStartupSubHeaderDetails.vue";
import PgaVerbaleStartupDeliverablesDetails from "./Components/PgaVerbaleStartupDeliverablesDetails.vue";
import PgaVerbaleStartupReferenteClienteDetails from "./Components/PgaVerbaleStartupReferenteClienteDetails.vue";
import PgaVerbaleStartupAdempimentiTeamHeaderDetails from "./Components/PgaVerbaleStartupAdempimentiTeamHeaderDetails.vue";
import PgaVerbaleStartupAdempimentiTeamConsultantDetails from "./Components/PgaVerbaleStartupAdempimentiTeamConsultantDetails.vue";
import PgaVerbaleStartupModalitaReportingDetails from "./Components/PgaVerbaleStartupModalitaReportingDetails.vue";
import PgaVerbaleStartupElementiIngressoDetails from "./Components/PgaVerbaleStartupElementiIngressoDetails.vue";
import PgaVerbaleStartupAltreInfoProgettoApplicativoDetails from "./Components/PgaVerbaleStartupAltreInfoProgettoApplicativoDetails.vue";
import PgaVerbaleStartupGovernanceProgettoApplicativoDetails from "./Components/PgaVerbaleStartupGovernanceProgettoApplicativoDetails.vue";
import PgaVerbaleStartupGovernanceServizioManutenzioneDetails from "./Components/PgaVerbaleStartupGovernanceServizioManutenzioneDetails.vue";
import PgaVerbaleStartupAltreInfoServizioManutenzioneDetails from "./Components/PgaVerbaleStartupAltreInfoServizioManutenzioneDetails.vue";
import PgaVerbaleStartupGovernanceProgettoInfrastrutturaDetails from "./Components/PgaVerbaleStartupGovernanceProgettoInfrastrutturaDetails.vue";
import PgaVerbaleStartupAltreInfoProgettoInfrastrutturaDetails from "./Components/PgaVerbaleStartupAltreInfoProgettoInfrastrutturaDetails.vue"

@Component({
  components: {
    PgaVerbaleStartupHeaderDetails,
    PgaVerbaleStartupSubHeaderDetails,
    PgaVerbaleStartupDeliverablesDetails,
    PgaVerbaleStartupReferenteClienteDetails,
    PgaVerbaleStartupAdempimentiTeamHeaderDetails,
    PgaVerbaleStartupAdempimentiTeamConsultantDetails,
    PgaVerbaleStartupModalitaReportingDetails,
    PgaVerbaleStartupElementiIngressoDetails,
    PgaVerbaleStartupAltreInfoProgettoApplicativoDetails,
    PgaVerbaleStartupGovernanceProgettoApplicativoDetails,
    PgaVerbaleStartupGovernanceServizioManutenzioneDetails,
    PgaVerbaleStartupAltreInfoServizioManutenzioneDetails,
    PgaVerbaleStartupGovernanceProgettoInfrastrutturaDetails,
    PgaVerbaleStartupAltreInfoProgettoInfrastrutturaDetails

  },
  data: () => ({}),
  computed: {
    PgaVerbaleStartupAltreInfoServizioManutenzioneDetails: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupAltreInfoServizioManutenzioneCreate;
      }
    },
    PgaVerbaleStartupHeaderDetails: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupHeaderCreate;
      }
    },
    PgaVerbaleStartupSubHeaderDetails: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupSubHeaderCreate;
      }
    },
    PgaVerbaleStartupDeliverablesDetails: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupDeliverableCreate;
      }
    },
    PgaVerbaleStartupReferenteClienteDetails: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupReferenteClienteCreate;
      }
    },
    PgaVerbaleStartupAdempimentiTeamHeaderDetails: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupAdempimentiTeamHeaderCreate;
      }
    },
    TipologiaVerbaleStepper: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupHeaderCreate.TipologiaVerbale;
      }
    },
    LabelAdempimentiTeam: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupAdempimentiTeamHeaderCreate.TipologiaVerbale;
      }
    },
    PgaVerbaleStartupAdempimentiTeamConsultantDetails: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupAdempimentiTeamConsultantCreate;
      }
    },
    PgaVerbaleStartupModalitaReportingDetails: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupModalitaReportingCreate;
      }
    },
    PgaVerbaleStartupElementiIngressoDetails: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupElementiIngressoCreate;
      }
    },
    PgaVerbaleStartupGovernanceProgettoApplicativoDetails: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupGovernanceProgettoApplicativoCreate;
      }
    },
    PgaVerbaleStartupAltreInfoProgettoApplicativoDetails: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupAltreInfoProgettoApplicativoCreate;
      }
    },
    PgaVerbaleStartupGovernanceServizioManutenzioneDetails: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupGovernanceServizioManutenzioneCreate;
      }
    },
    PgaVerbaleStartupGovernanceProgettoInfrastrutturaDetails: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreate;
      }
    },
    PgaVerbaleStartupAltreInfoProgettoInfrastrutturaDetails: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupAltreInfoProgettoInfrastrutturaCreate;
      }
    }
  }
})
export default class VerbaleStartupPageDetails extends Vue {}
