






























































































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";
import { PgaVerbaleStartupGovernanceProgettoApplicativoCreateModel } from "../store";

@Component({
  data: () => ({
    panel: [0, 1]
  }),
  computed: {
    PMGanttTeam: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupCampiComuni
          .PMGanttTeam;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_PMGANTTTEAM(value);
      }
    },
    TabellaMapping: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupCampiComuni.TabellaMapping;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_TABELLAMAPPING(value);
      }
    },
    Standard: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupCampiComuni.Standard;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_STANDARD(value);
      }
    },
    AltroStandard: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupCampiComuni.AltroStandard;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_ALTROSTANDARD(value);
      }
    },
    SpecificheFunzionali: {
      get() {
        return this.$props.Model.SpecificheFunzionali;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_SPECIFICHEFUNZIONALI(value);
      }
    },
    GiornateSpecificheFunzionali: {
      get() {
        return this.$props.Model.GiornateSpecificheFunzionali;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_GIORNATESPECIFICHEFUNZIONALI(value);
      }
    },
    SpecificheDatabase: {
      get() {
        return this.$props.Model.SpecificheDatabase;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_SPECIFICHEDATABASE(value);
      }
    },
    GiornateSpecificheDatabase: {
      get() {
        return this.$props.Model.GiornateSpecificheDatabase;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_GIORNATESPECIFICHEDATABASE(value);
      }
    },
    SpecificheTecniche: {
      get() {
        return this.$props.Model.SpecificheTecniche;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_SPECIFICHETECNICHE(value);
      }
    },
    GiornateSpecificheTecniche: {
      get() {
        return this.$props.Model.GiornateSpecificheTecniche;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_GIORNATESPECIFICHETECNICHE(value);
      }
    },
    DocumentoUAT: {
      get() {
        return this.$props.Model.DocumentoUAT;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_DOCUMENTOUAT(value);
      }
    },
    GiornateDocumentoUAT: {
      get() {
        return this.$props.Model.GiornateDocumentoUAT;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_GIORNATEDOCUMENTOUAT(value);
      }
    },
    AltroDocumentiGantt: {
      get() {
        return this.$props.Model.AltroDocumentiGantt;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_ALTRODOCUMENTIGANTT(value);
      }
    },
    TestoAltroDocumentiGantt: {
      get() {
        return this.$props.Model.TestoAltroDocumentiGantt;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_TESTOALTRODOCUMENTIGANTT(value);
      }
    }
  },
  methods: {
    checkGovernance(value) {
      let isVisible: boolean;
      isVisible = false;
      if (value) {
        if (value.includes("Proge")) isVisible = true;
      }
      return isVisible;
    }
  }
})
export default class PgaVerbaleStartupGovernanceProgettoApplicativoCreate extends Vue {
  @Model("", { type: Object })
  readonly Model!: PgaVerbaleStartupGovernanceProgettoApplicativoCreateModel;
}
