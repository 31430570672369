










































































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";
import { PgaVerbaleStartupAltreInfoProgettoApplicativoCreateModel } from "../store";

@Component({
  data: () => ({
    panel: [0, 1]
  }),
  computed: {
    PMVerificaProgettoApplicativo: {
      get() {
        return this.$props.Model.PMVerificaProgettoApplicativo;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_PMVERIFICA_PROGETTOAPPLICATIVO(value);
      }
    },
    AltroPMVerificaProgettoApplicativo: {
      get() {
        return this.$props.Model.AltroPMVerificaProgettoApplicativo;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_ALTROPMVERIFICA_PROGETTOAPPLICATIVO(
          value
        );
      }
    },
    PMRedazioneProgettoApllicativo: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupCampiComuni.PMRedazioneProgettoApllicativo;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_PMREDAZIONE_PROGETTOAPPLICATIVO(value);
      }
    },
    PMStrumentoProgettoApplicativo: {
      get() {
        return this.$props.Model.PMStrumentoProgettoApplicativo;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_PMSTRUMENTO_PROGETTOAPPLICATIVO(value);
      }
    },
    PMStrumentoProgettoApplicativoTesto: {
      get() {
        return this.$props.Model.PMStrumentoProgettoApplicativoTesto;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_PMSTRUMENTO_PROGETTOAPPLICATIVOTESTO(
          value
        );
      }
    },
    TeamPMStrumentoProgettoApplicativo: {
      get() {
        return this.$props.Model.TeamPMStrumentoProgettoApplicativo;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_TEAM_PMSTRUMENTO_PROGETTOAPPLICATIVO(
          value
        );
      }
    },
    TeamPMStrumentoProgettoApplicativoTesto: {
      get() {
        return this.$props.Model.TeamPMStrumentoProgettoApplicativoTesto;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_TEAM_PMSTRUMENTO_PROGETTOAPPLICATIVOTESTO(
          value
        );
      }
    },
    PMComunicazioneProgettoApllicativo: {
      get() {
        return this.$props.Model.PMComunicazioneProgettoApllicativo;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_PMCOMUNICAZIONE_PROGETTOAPPLICATIVOTESTO(
          value
        );
      }
    },
    PMSegnalazioneProgettoApllicativo: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupCampiComuni.PMSegnalazioneProgettoApllicativo;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_PMSEGNALAZIONE_PROGETTOAPPLICATIVOTESTO(
          value
        );
      }
    },
    NoteProgettoApplicativoTesto: {
      get() {
        return this.$props.Model.NoteProgettoApplicativoTesto;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_NOTE_PROGETTOAPPLICATIVOTESTO(
          value
        );
      }
    }
  }
})
export default class PgaVerbaleStartupAltreInfoProgettoApllicativoCreate extends Vue {
  @Model("", { type: Object })
  readonly Model!: PgaVerbaleStartupAltreInfoProgettoApplicativoCreateModel;
}
