

















import Vue from "vue";
import { Component } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";

@Component({
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Nome Deliverable",
          align: "start",
          sortable: false,
          value: "NomeDocumento"
        },
        { text: "Nome Documento", value: "NomeDocumento" },
      ]
    };
  },
  computed: {
    ElementiIngressoItems: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupElementiIngressoCreate;
      }
    }
  }
})
export default class PgaVerbaleStartupElementiIngressoDetails extends Vue {}
