import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/infrastructure/store'
import '@/App.container';
import MutationPayload from '@/types/Payload';
import { getServiceIdentifierAsString } from 'inversify';

export interface CreateVerbaleStartupState {
    VerbaleStartup: VerbaleStartup;
}

export interface VerbaleStartup {
    PgaVerbaleStartupHeaderCreate: PgaVerbaleStartupHeaderCreateModel;
    PgaVerbaleStartupSubHeaderCreate: PgaVerbaleStartupSubHeaderCreateModel;
    PgaVerbaleStartupDeliverableCreate: Array<PgaVerbaleStartupDeliverableCreateModel>;
    PgaVerbaleStartupReferenteClienteCreate: Array<PgaVerbaleStartupReferenteClienteCreateModel>;
    PgaVerbaleStartupAdempimentiTeamHeaderCreate: PgaVerbaleStartupAdempimentiTeamHeaderCreateModel;
    PgaVerbaleStartupAdempimentiTeamConsultantCreate: Array<PgaVerbaleStartupAdempimentiTeamConsultantCreateModel>;
    PgaVerbaleStartupModalitaReportingCreate: PgaVerbaleStartupModalitaReportingCreateModel;
    PgaVerbaleStartupElementiIngressoCreate: Array<PgaVerbaleStartupElementiIngressoCreateModel>;
    PgaVerbaleStartupGovernanceProgettoApplicativoCreate: PgaVerbaleStartupGovernanceProgettoApplicativoCreateModel;
    PgaVerbaleStartupAltreInfoProgettoApplicativoCreate: PgaVerbaleStartupAltreInfoProgettoApplicativoCreateModel;
    PgaVerbaleStartupGovernanceServizioManutenzioneCreate: PgaVerbaleStartupGovernanceServizioManutenzioneCreateModel;
    PgaVerbaleStartupAltreInfoServizioManutenzioneCreate: PgaVerbaleStartupAltreInfoServizioManutenzioneCreateModel;
    PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreate: PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreateModel;
    PgaVerbaleStartupCampiComuni: PgaVerbaleStartupCampiComuni;
    PgaVerbaleStartupAltreInfoProgettoInfrastrutturaCreate: PgaVerbaleStartupAltreInfoProgettoInfrastrutturaCreateModel;
}

export interface PgaVerbaleStartupHeaderCreateModel {
    NomeCliente: string;
    SiglaMnemonica: string;
    Descrizione: string;
    Data: Date;
    NominativoPM: string;
    TipologiaVerbale: string;
}

export interface PgaVerbaleStartupSubHeaderCreateModel {
    PiattaformaSviluppo: boolean;
    SviluppoTesto: string;
    PiattaformaTest: boolean;
    TestTesto: string;
    PiattaformaCollaudo: boolean;
    CollaudoTesto: string;
    PiattaformaProduzione: boolean;
    ProduzioneTesto: string;
    InformazioniRilevanti: string;
}

export interface PgaVerbaleStartupDeliverableCreateModel {
    DataConsegna: Date;
    NomeDeliverable: string;
}

export interface PgaVerbaleStartupReferenteClienteCreateModel {
    NomeReferenteCliente: string;
}
export interface PgaVerbaleStartupAdempimentiTeamConsultantCreateModel {
    NomeConsultant: string;
}

export interface PgaVerbaleStartupAdempimentiTeamHeaderCreateModel {
    NomeFigura: string;
    TipologiaVerbale: string;
}

export interface PgaVerbaleStartupModalitaReportingCreateModel {
    TipologiaReport: string;
    AltroTipologiaReport: string;
    PeriodoReport: string;
    AltroPeriodoReport: string;
}

export interface PgaVerbaleStartupElementiIngressoCreateModel {
    NomeDocumento: string;
}
export interface PgaVerbaleStartupGovernanceProgettoApplicativoCreateModel {
    SpecificheFunzionali: boolean;
    GiornateSpecificheFunzionali: string;
    SpecificheDatabase: boolean;
    GiornateSpecificheDatabase: string;
    SpecificheTecniche: boolean;
    GiornateSpecificheTecniche: string;
    DocumentoUAT: boolean;
    GiornateDocumentoUAT: string;
    AltroDocumentiGantt: boolean;
    TestoAltroDocumentiGantt: string;
}

export interface PgaVerbaleStartupGovernanceServizioManutenzioneCreateModel {
    PMProcedure: boolean;
    DocumentoPMProcedure: string;
    TroubleTicketing: boolean;
    ToolTroubleTicketing: string;
    AltroManutenzione: boolean;
    TestoAltroManutenzione: string;
}
export interface PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreateModel {
    AltroProgettoInfrastruttura: boolean;
    TestoAltroProgettoInfrastruttura: string;
    DocumentoArchitettura: boolean;
    GiornoDocumentoArchitettura: Date;
}
export interface PgaVerbaleStartupAltreInfoServizioManutenzioneCreateModel {
    TipologiaManutenzione: string;
    NoteServiziManutenzione: string;
}
export interface PgaVerbaleStartupAltreInfoProgettoApplicativoCreateModel {
    PMVerificaProgettoApplicativo: string;
    AltroPMVerificaProgettoApplicativo: string;
    PMStrumentoProgettoApplicativoTesto: string;
    PMStrumentoProgettoApplicativo: boolean;
    TeamPMStrumentoProgettoApplicativoTesto: string;
    TeamPMStrumentoProgettoApplicativo: boolean;
    PMComunicazioneProgettoApllicativo: boolean;
    NoteProgettoApplicativoTesto: string;
}
export interface PgaVerbaleStartupCampiComuni {
    PMGanttTeam: boolean;
    TabellaMapping: boolean;
    Standard: boolean;
    AltroStandard: string;
    PMSegnalazioneProgettoApllicativo: boolean;
    PMRedazioneProgettoApllicativo: boolean;
    PMBackup: boolean;
}
export interface PgaVerbaleStartupAltreInfoProgettoInfrastrutturaCreateModel {
    NoteProgettoInfrastruttura: string;
}
@Module({ namespaced: true, dynamic: true, store, name: 'CreateVerbaleStartupModule', })
class CreateVerbaleStartupStore extends VuexModule implements CreateVerbaleStartupState {
    VerbaleStartup: VerbaleStartup = {
        PgaVerbaleStartupHeaderCreate: {
            NomeCliente: "",
            SiglaMnemonica: "",
            Descrizione: "",
            Data: new Date(),
            NominativoPM: "",
            TipologiaVerbale: "",
        },
        PgaVerbaleStartupSubHeaderCreate: {
            PiattaformaSviluppo: false,
            SviluppoTesto: "",
            PiattaformaTest: false,
            TestTesto: "",
            PiattaformaCollaudo: false,
            CollaudoTesto: "",
            PiattaformaProduzione: false,
            ProduzioneTesto: "",
            InformazioniRilevanti: "",
        },
        PgaVerbaleStartupDeliverableCreate: [],
        PgaVerbaleStartupReferenteClienteCreate: [],
        PgaVerbaleStartupAdempimentiTeamHeaderCreate: {
            NomeFigura: "",
            TipologiaVerbale: "Project Manager",
        },
        PgaVerbaleStartupAdempimentiTeamConsultantCreate: [],
        PgaVerbaleStartupModalitaReportingCreate: {
            TipologiaReport: "",
            AltroTipologiaReport: "",
            PeriodoReport: "",
            AltroPeriodoReport: "",
        },
        PgaVerbaleStartupElementiIngressoCreate: [],
        PgaVerbaleStartupGovernanceProgettoApplicativoCreate: {
            SpecificheFunzionali: false,
            GiornateSpecificheFunzionali: "",
            SpecificheDatabase: false,
            GiornateSpecificheDatabase: "",
            SpecificheTecniche: false,
            GiornateSpecificheTecniche: "",
            DocumentoUAT: false,
            GiornateDocumentoUAT: "",
            AltroDocumentiGantt: false,
            TestoAltroDocumentiGantt: "",
        },
        PgaVerbaleStartupGovernanceServizioManutenzioneCreate: {
            PMProcedure: false,
            DocumentoPMProcedure: "",
            TroubleTicketing: false,
            ToolTroubleTicketing: "",
            AltroManutenzione: false,
            TestoAltroManutenzione: "",
        },
        PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreate: {
            AltroProgettoInfrastruttura: false,
            TestoAltroProgettoInfrastruttura: "",
            DocumentoArchitettura: false,
            GiornoDocumentoArchitettura: new Date(),
        },
        PgaVerbaleStartupAltreInfoServizioManutenzioneCreate: {
            TipologiaManutenzione: "",
            NoteServiziManutenzione: "",
        },
        PgaVerbaleStartupAltreInfoProgettoApplicativoCreate: {
            PMVerificaProgettoApplicativo: "",
            AltroPMVerificaProgettoApplicativo: "",
            PMStrumentoProgettoApplicativo: false,
            PMStrumentoProgettoApplicativoTesto: "",
            TeamPMStrumentoProgettoApplicativo: false,
            TeamPMStrumentoProgettoApplicativoTesto: "",
            PMComunicazioneProgettoApllicativo: false,
            NoteProgettoApplicativoTesto: "",
        },
        PgaVerbaleStartupCampiComuni: {
            PMGanttTeam: false,
            TabellaMapping: false,
            Standard: false,
            PMSegnalazioneProgettoApllicativo: false,
            AltroStandard: "",
            PMRedazioneProgettoApllicativo: false,
            PMBackup: false,
        },
        PgaVerbaleStartupAltreInfoProgettoInfrastrutturaCreate: {
            NoteProgettoInfrastruttura: "",
        }
    };

    //HEADER

    @Mutation
    public SET_NOMECLIENTE(NomeCliente: string): void {
        this.VerbaleStartup.PgaVerbaleStartupHeaderCreate.NomeCliente = NomeCliente;
    }
    @Mutation
    public SET_DESCRIZIONE(Descrizione: string): void {
        this.VerbaleStartup.PgaVerbaleStartupHeaderCreate.Descrizione = Descrizione;
    }
    @Mutation
    public SET_DATA(Data: Date): void {
        this.VerbaleStartup.PgaVerbaleStartupHeaderCreate.Data = Data;
    }
    @Mutation
    public SET_NOMINATIVOPM(NominativoPM: string): void {
        this.VerbaleStartup.PgaVerbaleStartupHeaderCreate.NominativoPM = NominativoPM;
    }
    @Mutation
    public SET_SIGLAMNEMONICA(SiglaMnemonica: string): void {
        this.VerbaleStartup.PgaVerbaleStartupHeaderCreate.SiglaMnemonica = SiglaMnemonica;
    }
    @Mutation
    public SET_TIPOLOGIAVERBALE(TipologiaVerbale: string): void {
        this.VerbaleStartup.PgaVerbaleStartupHeaderCreate.TipologiaVerbale = TipologiaVerbale;
        if (TipologiaVerbale == "Servizio di Manutenzione")
            this.VerbaleStartup.PgaVerbaleStartupAdempimentiTeamHeaderCreate.TipologiaVerbale = "Service Manager";
        else
            this.VerbaleStartup.PgaVerbaleStartupAdempimentiTeamHeaderCreate.TipologiaVerbale = "Project Manager";

    }
    //SUB HEADER
    @Mutation
    public SET_PIATTAFORMASVILUPPO(PiattaformaSviluppo: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupSubHeaderCreate.PiattaformaSviluppo = PiattaformaSviluppo;
    }
    @Mutation
    public SET_SVILUPPOTESTO(SviluppoTesto: string): void {
        this.VerbaleStartup.PgaVerbaleStartupSubHeaderCreate.SviluppoTesto = SviluppoTesto;
    }
    @Mutation
    public SET_PIATTAFORMATEST(PiattaformaTest: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupSubHeaderCreate.PiattaformaTest = PiattaformaTest;
    }
    @Mutation
    public SET_TESTTESTO(TestTesto: string): void {
        this.VerbaleStartup.PgaVerbaleStartupSubHeaderCreate.TestTesto = TestTesto;
    }
    @Mutation
    public SET_PIATTAFORMACOLLAUDO(PiattaformaCollaudo: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupSubHeaderCreate.PiattaformaCollaudo = PiattaformaCollaudo;
    }
    @Mutation
    public SET_COLLAUDOTESTO(CollaudoTesto: string): void {
        this.VerbaleStartup.PgaVerbaleStartupSubHeaderCreate.CollaudoTesto = CollaudoTesto;
    }
    @Mutation
    public SET_PIATTAFORMAPRODUZIONE(PiattaformaProduzione: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupSubHeaderCreate.PiattaformaProduzione = PiattaformaProduzione;
    }
    @Mutation
    public SET_PRODUZIONETESTO(ProduzioneTesto: string): void {
        this.VerbaleStartup.PgaVerbaleStartupSubHeaderCreate.ProduzioneTesto = ProduzioneTesto;
    }
    @Mutation
    public SET_INFORMAZIONIRILEVANTI(InformazioniRilevanti: string): void {
        this.VerbaleStartup.PgaVerbaleStartupSubHeaderCreate.InformazioniRilevanti = InformazioniRilevanti;
    }

    //Deliverables
    @Mutation
    public SET_DELIVERABLE(payload: MutationPayload<string>): void {
        this.VerbaleStartup.PgaVerbaleStartupDeliverableCreate[payload.index].NomeDeliverable = payload.value;
    }
    @Mutation
    public SET_DATACONSEGNA(payload: MutationPayload<Date>): void {
        this.VerbaleStartup.PgaVerbaleStartupDeliverableCreate[payload.index].DataConsegna = payload.value;
    }
    @Mutation
    public REMOVE_DELIVERABLE(index: number): void {
        this.VerbaleStartup.PgaVerbaleStartupDeliverableCreate.splice(index, 1);
    }
    @Mutation
    public ADD_DELIVERABLE(): void {
        this.VerbaleStartup.PgaVerbaleStartupDeliverableCreate.push({
            NomeDeliverable: "",
            DataConsegna: new Date(),
        } as PgaVerbaleStartupDeliverableCreateModel);
    }

    //Referente cliente
    @Mutation
    public SET_REFERENTECLIENTE(payload: MutationPayload<string>): void {
        this.VerbaleStartup.PgaVerbaleStartupReferenteClienteCreate[payload.index].NomeReferenteCliente = payload.value;
    }
    @Mutation
    public REMOVE_REFERENTECLIENTE(index: number): void {
        this.VerbaleStartup.PgaVerbaleStartupReferenteClienteCreate.splice(index, 1);
    }
    @Mutation
    public ADD_REFERENTECLIENTE(): void {
        this.VerbaleStartup.PgaVerbaleStartupReferenteClienteCreate.push({
            NomeReferenteCliente: "",
        } as PgaVerbaleStartupReferenteClienteCreateModel);
    }

    //Adempimenti team
    @Mutation
    public SET_NOMEFIGURA(NomeFigura: string): void {
        this.VerbaleStartup.PgaVerbaleStartupAdempimentiTeamHeaderCreate.NomeFigura = NomeFigura;
    }

    @Mutation
    public SET_NOMECONSULTANT(payload: MutationPayload<string>): void {
        this.VerbaleStartup.PgaVerbaleStartupAdempimentiTeamConsultantCreate[payload.index].NomeConsultant = payload.value;
    }
    @Mutation
    public REMOVE_CONSULTANT(index: number): void {
        this.VerbaleStartup.PgaVerbaleStartupAdempimentiTeamConsultantCreate.splice(index, 1);
    }
    @Mutation
    public ADD_ADEMPIMENTICONSULTANT(): void {
        this.VerbaleStartup.PgaVerbaleStartupAdempimentiTeamConsultantCreate.push({
            NomeConsultant: "",
        } as PgaVerbaleStartupAdempimentiTeamConsultantCreateModel);
    }

    //Modalita Reporting
    @Mutation
    public SET_TIPOLOGIAREPORT(TipologiaReport: string): void {
        this.VerbaleStartup.PgaVerbaleStartupModalitaReportingCreate.TipologiaReport = TipologiaReport;
    }
    @Mutation
    public SET_ALTROTIPOLOGIAREPORT(AltroTipologiaReport: string): void {
        this.VerbaleStartup.PgaVerbaleStartupModalitaReportingCreate.AltroTipologiaReport = AltroTipologiaReport;
    }
    @Mutation
    public SET_PERIODOREPORT(PeriodoReport: string): void {
        this.VerbaleStartup.PgaVerbaleStartupModalitaReportingCreate.PeriodoReport = PeriodoReport;
    }
    @Mutation
    public SET_ALTROPERIODOREPORT(AltroPeriodoReport: string): void {
        this.VerbaleStartup.PgaVerbaleStartupModalitaReportingCreate.AltroPeriodoReport = AltroPeriodoReport;
    }

    //Elementi Ingresso
    @Mutation
    public SET_NOMEDOCUMENTO(payload: MutationPayload<string>): void {
        this.VerbaleStartup.PgaVerbaleStartupElementiIngressoCreate[payload.index].NomeDocumento = payload.value;
    }
    @Mutation
    public REMOVE_ELEMENTIINGRESSO(index: number): void {
        this.VerbaleStartup.PgaVerbaleStartupElementiIngressoCreate.splice(index, 1);
    }
    @Mutation
    public ADD_ELEMENTIINGRESSO(): void {
        this.VerbaleStartup.PgaVerbaleStartupElementiIngressoCreate.push({
            NomeDocumento: "",
        } as PgaVerbaleStartupElementiIngressoCreateModel);
    }

    //Governance progetto applicativo

    @Mutation
    public SET_PMGANTTTEAM(PMGanttTeam: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupCampiComuni.PMGanttTeam = PMGanttTeam;
    }
    @Mutation
    public SET_TABELLAMAPPING(TabellaMapping: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupCampiComuni.TabellaMapping = TabellaMapping;
    }
    @Mutation
    public SET_STANDARD(Standard: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupCampiComuni.Standard = Standard;
    }
    @Mutation
    public SET_ALTROSTANDARD(AltroStandard: string): void {
        this.VerbaleStartup.PgaVerbaleStartupCampiComuni.AltroStandard = AltroStandard;
    }
    @Mutation
    public SET_SPECIFICHEFUNZIONALI(SpecificheFunzionali: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceProgettoApplicativoCreate.SpecificheFunzionali = SpecificheFunzionali;
    }

    @Mutation
    public SET_GIORNATESPECIFICHEFUNZIONALI(GiornateSpecificheFunzionali: string): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceProgettoApplicativoCreate.GiornateSpecificheFunzionali = GiornateSpecificheFunzionali;
    }
    @Mutation
    public SET_SPECIFICHEDATABASE(SpecificheDatabase: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceProgettoApplicativoCreate.SpecificheDatabase = SpecificheDatabase;
    }

    @Mutation
    public SET_GIORNATESPECIFICHEDATABASE(SpecificheTecniche: string): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceProgettoApplicativoCreate.GiornateSpecificheDatabase = SpecificheTecniche;
    }


    @Mutation
    public SET_SPECIFICHETECNICHE(SpecificheTecniche: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceProgettoApplicativoCreate.SpecificheTecniche = SpecificheTecniche;
    }

    @Mutation
    public SET_GIORNATESPECIFICHETECNICHE(GiornateSpecificheTecniche: string): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceProgettoApplicativoCreate.GiornateSpecificheTecniche = GiornateSpecificheTecniche;
    }
    @Mutation
    public SET_DOCUMENTOUAT(DocumentoUAT: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceProgettoApplicativoCreate.DocumentoUAT = DocumentoUAT;
    }

    @Mutation
    public SET_GIORNATEDOCUMENTOUAT(GiornateDocumentoUAT: string): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceProgettoApplicativoCreate.GiornateDocumentoUAT = GiornateDocumentoUAT;
    }
    @Mutation
    public SET_ALTRODOCUMENTIGANTT(AltroDocumentiGantt: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceProgettoApplicativoCreate.AltroDocumentiGantt = AltroDocumentiGantt;
    }

    @Mutation
    public SET_TESTOALTRODOCUMENTIGANTT(TestoAltroDocumentiGantt: string): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceProgettoApplicativoCreate.TestoAltroDocumentiGantt = TestoAltroDocumentiGantt;
    }

    //Altre info progetto applicativo
    @Mutation
    public SET_PMVERIFICA_PROGETTOAPPLICATIVO(PMVerificaProgettoApplicativo: string): void {
        this.VerbaleStartup.PgaVerbaleStartupAltreInfoProgettoApplicativoCreate.PMVerificaProgettoApplicativo = PMVerificaProgettoApplicativo;
    }
    @Mutation
    public SET_ALTROPMVERIFICA_PROGETTOAPPLICATIVO(AltroPMVerificaProgettoApplicativo: string): void {
        this.VerbaleStartup.PgaVerbaleStartupAltreInfoProgettoApplicativoCreate.AltroPMVerificaProgettoApplicativo = AltroPMVerificaProgettoApplicativo;
    }
    @Mutation
    public SET_PMREDAZIONE_PROGETTOAPPLICATIVO(PMRedazioneProgettoApllicativo: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupCampiComuni.PMRedazioneProgettoApllicativo = PMRedazioneProgettoApllicativo;
    }
    @Mutation
    public SET_PMSTRUMENTO_PROGETTOAPPLICATIVO(PMStrumentoProgettoApplicativo: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupAltreInfoProgettoApplicativoCreate.PMStrumentoProgettoApplicativo = PMStrumentoProgettoApplicativo;
    }
    @Mutation
    public SET_PMSTRUMENTO_PROGETTOAPPLICATIVOTESTO(PMStrumentoProgettoApplicativoTesto: string): void {
        this.VerbaleStartup.PgaVerbaleStartupAltreInfoProgettoApplicativoCreate.PMStrumentoProgettoApplicativoTesto = PMStrumentoProgettoApplicativoTesto;
    }
    @Mutation
    public SET_TEAM_PMSTRUMENTO_PROGETTOAPPLICATIVO(TeamPMStrumentoProgettoApplicativo: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupAltreInfoProgettoApplicativoCreate.TeamPMStrumentoProgettoApplicativo = TeamPMStrumentoProgettoApplicativo;
    }
    @Mutation
    public SET_TEAM_PMSTRUMENTO_PROGETTOAPPLICATIVOTESTO(TeamPMStrumentoProgettoApplicativoTesto: string): void {
        this.VerbaleStartup.PgaVerbaleStartupAltreInfoProgettoApplicativoCreate.TeamPMStrumentoProgettoApplicativoTesto = TeamPMStrumentoProgettoApplicativoTesto;
    }
    @Mutation
    public SET_PMCOMUNICAZIONE_PROGETTOAPPLICATIVOTESTO(PMComunicazioneProgettoApllicativo: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupAltreInfoProgettoApplicativoCreate.PMComunicazioneProgettoApllicativo = PMComunicazioneProgettoApllicativo;
    }
    @Mutation
    public SET_PMSEGNALAZIONE_PROGETTOAPPLICATIVOTESTO(PMSegnalazioneProgettoApllicativo: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupCampiComuni.PMSegnalazioneProgettoApllicativo = PMSegnalazioneProgettoApllicativo;
    }
    @Mutation
    public SET_NOTE_PROGETTOAPPLICATIVOTESTO(NoteProgettoApplicativoTesto: string): void {
        this.VerbaleStartup.PgaVerbaleStartupAltreInfoProgettoApplicativoCreate.NoteProgettoApplicativoTesto = NoteProgettoApplicativoTesto;
    }

    //Governance servizio manutenzione

    @Mutation
    public SET_PMPROCEDURE(PMProcedure: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceServizioManutenzioneCreate.PMProcedure = PMProcedure;
    }
    @Mutation
    public SET_DOCUMENTOPMPROCEDURE(DocumentoPMProcedure: string): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceServizioManutenzioneCreate.DocumentoPMProcedure = DocumentoPMProcedure;
    }
    @Mutation
    public SET_TROUBLETICKETING(TroubleTicketing: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceServizioManutenzioneCreate.TroubleTicketing = TroubleTicketing;
    }
    @Mutation
    public SET_TOOLTROUBLETICKETING(ToolTroubleTicketing: string): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceServizioManutenzioneCreate.ToolTroubleTicketing = ToolTroubleTicketing;
    }
    @Mutation
    public SET_ALTROMANUTENZIONE(AltroManutenzione: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceServizioManutenzioneCreate.AltroManutenzione = AltroManutenzione;
    }

    @Mutation
    public SET_TESTOALTROMANUTENZIONE(TestoAltroManutenzione: string): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceServizioManutenzioneCreate.TestoAltroManutenzione = TestoAltroManutenzione;
    }

    //altre info servizio manutenzione
    @Mutation
    public SET_PMBACKUP(PMBackup: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupCampiComuni.PMBackup = PMBackup;
    }
    @Mutation
    public SET_TIPOLOGIAMANUTENZIONE(TipologiaManutenzione: string): void {
        this.VerbaleStartup.PgaVerbaleStartupAltreInfoServizioManutenzioneCreate.TipologiaManutenzione = TipologiaManutenzione;
    }
    @Mutation
    public SET_NOTESERVIZIMANUTENZIONE(NoteServiziManutenzione: string): void {
        this.VerbaleStartup.PgaVerbaleStartupAltreInfoServizioManutenzioneCreate.NoteServiziManutenzione = NoteServiziManutenzione;
    }

    //gvernance progetto infrastruttura
    @Mutation
    public SET_ALTROPROGETTOINFRASTRUTTURA(AltroProgettoInfrastruttura: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreate.AltroProgettoInfrastruttura = AltroProgettoInfrastruttura;
    }
    @Mutation
    public SET_TESTOALTROPROGETTO_INFRASTRUTTURA(TestoAltroProgettoInfrastruttura: string): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreate.TestoAltroProgettoInfrastruttura = TestoAltroProgettoInfrastruttura;
    }
    @Mutation
    public SET_GIORNODOCUMENTOARCHITETTURA(GiornoDocumentoArchitettura: Date): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreate.GiornoDocumentoArchitettura = GiornoDocumentoArchitettura;
    }
    @Mutation
    public SET_DOCUMENTOARCHITETTURA(DocumentoArchitettura: boolean): void {
        this.VerbaleStartup.PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreate.DocumentoArchitettura = DocumentoArchitettura;
    }

    //AltreInfoProgettoInfrastruttura
    @Mutation
    public SET_NOTEPROGETTOINFRASTRUTTURA(NoteProgettoInfrastruttura: string): void {
        this.VerbaleStartup.PgaVerbaleStartupAltreInfoProgettoInfrastrutturaCreate.NoteProgettoInfrastruttura = NoteProgettoInfrastruttura;
    }
}

export const CreateVerbaleStartupModule = getModule(CreateVerbaleStartupStore);
