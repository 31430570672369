

































import Vue from "vue";
import { Component, Prop, Model, Emit } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";
import { PgaVerbaleStartupAdempimentiTeamConsultantCreateModel } from "../store";

@Component({
  data: () => ({
  }),
  computed: {
    NomeConsultant: {
      get() {
        return this.$props.Model.NomeConsultant;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_NOMECONSULTANT({
          index: this.$props.index,
          value: value
        });
      }
    },
  }
})
export default class PgaVerbaleStartupAdempimentiTeamConsultantCreate extends Vue {
  @Prop(Number) readonly index!: number;
  @Model("", { type: Object }) readonly Model!: PgaVerbaleStartupAdempimentiTeamConsultantCreateModel;

  @Emit()
  removeAdempimentiTeamConsultant() {
    CreateVerbaleStartupModule.REMOVE_CONSULTANT(this.index);
  }
}
