


































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";
import { PgaVerbaleStartupSubHeaderCreateModel } from "../store";
import { OfferServiceModule } from "../../store";

@Component({
  data: () => ({}),
  computed: {
    piattaformaSviluppo: {
      get() {
        return this.$props.Model.PiattaformaSviluppo;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_PIATTAFORMASVILUPPO(value);
      }
    },
    sviluppoTesto: {
      get() {
        return this.$props.Model.SviluppoTesto;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_SVILUPPOTESTO(value);
      }
    },
    piattaformaTest: {
      get() {
        return this.$props.Model.PiattaformaTest;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_PIATTAFORMATEST(value);
      }
    },
    testTesto: {
      get() {
        return this.$props.Model.TestTesto;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_TESTTESTO(value);
      }
    },
    piattaformaCollaudo: {
      get() {
        return this.$props.Model.PiattaformaCollaudo;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_PIATTAFORMACOLLAUDO(value);
      }
    },
    collaudoTesto: {
      get() {
        return this.$props.Model.CollaudoTesto;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_COLLAUDOTESTO(value);
      }
    },
    piattaformaProduzione: {
      get() {
        return this.$props.Model.PiattaformaProduzione;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_PIATTAFORMAPRODUZIONE(value);
      }
    },
    produzioneTesto: {
      get() {
        return this.$props.Model.ProduzioneTesto;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_PRODUZIONETESTO(value);
      }
    },
     informazioniRilevanti: {
      get() {
        return this.$props.Model.InformazioniRilevanti;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_INFORMAZIONIRILEVANTI(value);
      }
    },
  }
})
export default class PgaVerbaleStartupCreateSubHeaderCreate extends Vue {
  @Model("", { type: Object })
  readonly Model!: PgaVerbaleStartupSubHeaderCreateModel;
}
