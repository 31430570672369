

















import Vue from "vue";
import { Component } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";

@Component({
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Nome Consultant",
          align: "start",
          sortable: false,
          value: "NomeConsultant"
        },
      ]
    };
  },
  computed: {
    AdempimentiTeamItems: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupAdempimentiTeamConsultantCreate;
      }
    }
  }
})
export default class PgaVerbaleStartupAdempimentiTeamConsultantDetails extends Vue {}
