









































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";
import { PgaVerbaleStartupModalitaReportingCreateModel } from "../store";

@Component({
  data: () => ({}),
  computed: {
    tipologiaReport: {
      get() {
        return this.$props.Model.TipologiaReport;
      }
    },
    altroTipologiaReport: {
      get() {
        return this.$props.Model.AltroTipologiaReport;
      }
    },
    periodoReport: {
      get() {
        return this.$props.Model.PeriodoReport;
      }
    },
    altroPeriodoReport: {
      get() {
        return this.$props.Model.AltroPeriodoReport;
      }
    }
  },
  methods: {
    checkDropDown(value) {
      let isVisible: boolean;
      isVisible = false;
      if (value) {
        if (value.includes("Altro")) isVisible = true;
      }
      return isVisible;
    }
  }
})
export default class PgaVerbaleStartupModalitaReportingDetails extends Vue {
  @Model("", { type: Object }) readonly Model!: PgaVerbaleStartupModalitaReportingCreateModel;
}
