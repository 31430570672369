




































import Vue from "vue";
import { Component, Prop, Model, Emit } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";
import { PgaVerbaleStartupDeliverableCreateModel } from "../store";

@Component({
  data: () => ({
    Deliverable: undefined
  }),
  computed: {
    NomeDeliverable: {
      get() {
        return this.$props.Model.NomeDeliverable;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_DELIVERABLE({
          index: this.$props.index,
          value: value
        });
      }
    },
    DataConsegna: {
      get() {
        return this.$props.Model.DataConsegna;
      },
      set(value: Date) {
        CreateVerbaleStartupModule.SET_DATACONSEGNA({
          index: this.$props.index,
          value: value
        });
      }
    }
  }
})
export default class PgaVerbaleStartupDeliverableCreate extends Vue {
  @Prop(Number) readonly index!: number;
  @Model("", { type: Object }) readonly Model!: PgaVerbaleStartupDeliverableCreateModel;

  @Emit()
  removeDeliverable() {
    CreateVerbaleStartupModule.REMOVE_DELIVERABLE(this.index);
  }
}
