

































import Vue from "vue";
import { Component, Prop, Model, Emit } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";
import { PgaVerbaleStartupElementiIngressoCreateModel } from "../store";

@Component({
  data: () => ({
    ElementiIngresso: undefined
  }),
  computed: {
    NomeDocumento: {
      get() {
        return this.$props.Model.NomeDocumento;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_NOMEDOCUMENTO({
          index: this.$props.index,
          value: value
        });
      }
    },
  }
})
export default class PgaVerbaleStartupElementiIngressoCreateCreate extends Vue {
  @Prop(Number) readonly index!: number;
  @Model("", { type: Object }) readonly Model!: PgaVerbaleStartupElementiIngressoCreateModel;

  @Emit()
  removeElementiIngresso() {
    CreateVerbaleStartupModule.REMOVE_ELEMENTIINGRESSO(this.index);
  }
}
