



















import Vue from "vue";
import { Component, Prop, Model, Emit } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "./store";
@Component({
  data() {
    return {
      bottomNav: "recent",
      search: "",
      headers: [
        {
          text: "Nome Cliente",
          value: "NomeCliente"
        },
        { text: "Sigla Mnemonica", value: "SiglaMnemonica" },
        { text: "Descrizione", value: "Descrizione" },
        { text: "NominativoPM", value: "NominativoPM" },
        { text: "Tipologia Verbale", value: "TipologiaVerbale" }
      ]
    };
  },
  computed: {},  
})
export default class VerbaleStartupIndex extends Vue {
  details(data: any) {
    this.$router.push({ path: "verbalestartup/details" });
  }
  get items() {
    return [
      CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupHeaderCreate
    ];
  }
}
