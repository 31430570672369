
































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";
import { PgaVerbaleStartupAltreInfoProgettoInfrastrutturaCreateModel } from "../store";

@Component({
  data: () => ({
    panel: [0, 1]
  }),
  computed: {
    PMCriticita: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupCampiComuni
          .PMRedazioneProgettoApllicativo;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_PMREDAZIONE_PROGETTOAPPLICATIVO(value);
      }
    },
    PMBackup: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupCampiComuni.PMBackup;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_PMBACKUP(value);
      }
    },
    PMSegnalazione: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupCampiComuni.PMSegnalazioneProgettoApllicativo;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_PMSEGNALAZIONE_PROGETTOAPPLICATIVOTESTO(value);
      }
    },
    NoteProgettoInfrastruttura: {
      get() {
        return this.$props.Model.NoteProgettoInfrastruttura;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_NOTEPROGETTOINFRASTRUTTURA(value);
      }
    }
  }
})
export default class PgaVerbaleStartupAltreInfoProgettoInfrastrutturaCreate extends Vue {
  @Model("", { type: Object })
  readonly Model!: PgaVerbaleStartupAltreInfoProgettoInfrastrutturaCreateModel;
}
