







































































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";
import { PgaVerbaleStartupGovernanceServizioManutenzioneCreateModel } from "../store";

@Component({
  data: () => ({
    panel: [0, 1]
  }),
  computed: {
    PMProcedure: {
      get() {
        return this.$props.Model.PMProcedure;
      },
    },
    DocumentoPMProcedure: {
      get() {
        return this.$props.Model.DocumentoPMProcedure;
      },
    },
    MappingManutenzione: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup
          .PgaVerbaleStartupCampiComuni.TabellaMapping;
      },
    },
    TroubleTicketing: {
      get() {
        return this.$props.Model.TroubleTicketing;
      },
    },
    ToolTroubleTicketing: {
      get() {
        return this.$props.Model.ToolTroubleTicketing;
      },
    },
    AltroManutenzione: {
      get() {
        return this.$props.Model.AltroManutenzione;
      },
    },
    TestoAltroManutenzione: {
      get() {
        return this.$props.Model.TestoAltroManutenzione;
      },
    }
  }
})
export default class PgaVerbaleStartupGovernanceServizioManutenzioneDetails extends Vue {
  @Model("", { type: Object })
  readonly Model!: PgaVerbaleStartupGovernanceServizioManutenzioneCreateModel;
}
