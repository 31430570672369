

















































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";
import { PgaVerbaleStartupGovernanceServizioManutenzioneCreateModel } from "../store";

@Component({
  data: () => ({
    panel: [0, 1]
  }),
  computed: {
    PMProcedure: {
      get() {
        return this.$props.Model.PMProcedure;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_PMPROCEDURE(value);
      }
    },
    DocumentoPMProcedure: {
      get() {
        return this.$props.Model.DocumentoPMProcedure;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_DOCUMENTOPMPROCEDURE(value);
      }
    },
    MappingManutenzione: {
      get() {
        return CreateVerbaleStartupModule.VerbaleStartup.PgaVerbaleStartupCampiComuni
          .TabellaMapping;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_TABELLAMAPPING(value);
      }
    },
    TroubleTicketing: {
      get() {
        return this.$props.Model.TroubleTicketing;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_TROUBLETICKETING(value);
      }
    },
    ToolTroubleTicketing: {
      get() {
        return this.$props.Model.ToolTroubleTicketing;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_TOOLTROUBLETICKETING(value);
      }
    },
    AltroManutenzione: {
      get() {
        return this.$props.Model.AltroManutenzione;
      },
      set(value: boolean) {
        CreateVerbaleStartupModule.SET_ALTROMANUTENZIONE(value);
      }
    },
    TestoAltroManutenzione: {
      get() {
        return this.$props.Model.TestoAltroManutenzione;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_TESTOALTROMANUTENZIONE(value);
      }
    }
  }
})
export default class PgaVerbaleStartupGovernanceServizioManutenzioneCreate extends Vue {
  @Model("", { type: Object })
  readonly Model!: PgaVerbaleStartupGovernanceServizioManutenzioneCreateModel;
}
