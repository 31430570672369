










import Vue from "vue";
import { Component, Prop, Model, Emit } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";
import { PgaVerbaleStartupAdempimentiTeamHeaderCreateModel } from "../store";

@Component({
  data: () => ({}),
  computed: {
    nomeFigura: {
      get() {
        return this.$props.Model.NomeFigura;
      },
      set(value: string) {
        CreateVerbaleStartupModule.SET_NOMEFIGURA(value);
      }
    },
    tipologiaVerbale: {
      get() {
          return this.$props.TipologiaVerbale
      }
    }
  }
})
export default class PgaVerbaleStartupAdempimentiTeamHeaderCreate extends Vue {
  @Prop(String) readonly TipologiaVerbale!: string;
  @Model("", { type: Object }) readonly Model!: PgaVerbaleStartupAdempimentiTeamHeaderCreateModel;
}
