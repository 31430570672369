












































































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { CreateVerbaleStartupModule } from "../store";
import { PgaVerbaleStartupSubHeaderCreateModel } from "../store";
@Component({
  data: () => ({}),
  computed: {
    piattaformaSviluppo: {
      get() {
        return this.$props.Model.PiattaformaSviluppo;
      },
    },
    sviluppoTesto: {
      get() {
        return this.$props.Model.SviluppoTesto;
      },
    },
    piattaformaTest: {
      get() {
        return this.$props.Model.PiattaformaTest;
      },
    },
    testTesto: {
      get() {
        return this.$props.Model.TestTesto;
      },
    },
    piattaformaCollaudo: {
      get() {
        return this.$props.Model.PiattaformaCollaudo;
      },
    },
    collaudoTesto: {
      get() {
        return this.$props.Model.CollaudoTesto;
      },
    },
    piattaformaProduzione: {
      get() {
        return this.$props.Model.PiattaformaProduzione;
      },
    },
    produzioneTesto: {
      get() {
        return this.$props.Model.ProduzioneTesto;
      },
    },
    informazioniRilevanti: {
      get() {
        return this.$props.Model.InformazioniRilevanti;
      },
    }
  }
})
export default class PgaVerbaleStartupSubHeaderDetails extends Vue {
  @Model("", { type: Object })
  readonly Model!: PgaVerbaleStartupSubHeaderCreateModel;
}
